<template>
  <v-sheet
    class="group content bg-transparent"
    @click="focus"
    :height="height"
    :min-height="minHeight"
    :max-height="maxHeight"
  >
    <div
      class="relative h-full overflow-hidden"
      :class="{ 'rounded-md': !outlined }"
    >
      <div
        class="absolute inset-0 bg-[currentColor] opacity-4 group-hover:opacity-7 group-focus:opacity-16"
        style="transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1)"
      ></div>
      <span v-if="placeholder && !charCount" class="placeholder">
        {{ placeholder }}
      </span>
      <div class="relative max-h-full overflow-auto">
        <editor-content :editor="editor" class="h-full pa-2" />
      </div>
    </div>
  </v-sheet>
</template>

<script lang="ts">
import { Editor, EditorContent } from "@tiptap/vue-3";

export default defineComponent({
  components: {
    EditorContent,
  },
  props: {
    editor: { type: Object as PropType<Editor> },
    placeholder: { type: String },
    height: { type: [String, Number] },
    minHeight: { type: [String, Number], default: "150px" },
    maxHeight: { type: [String, Number], default: "300px" },
    charCount: { type: [Number, String] },
    outlined: { type: Boolean, default: false },
  },
  methods: {
    focus() {
      this.editor!.commands.focus();
    },
  },
});
</script>
