import { Editor } from "@tiptap/vue-3";
import Code from "@tiptap/extension-code";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Typography from "@tiptap/extension-typography";
import History from "@tiptap/extension-history";
import ListItem from "@tiptap/extension-list-item";
import ColorHighlighter from "./ColorHighlighter";
import SmilieReplacer from "./SmilieReplacer";
import Header from "./Header.vue";
import Content from "./Content.vue";
import Footer from "./Footer.vue";
import "./style.scss";

export type EditorTool = {
  title: string;
  shortcut?: string;
  active?: { (editor: Editor): boolean };
  handle: { (editor: Editor): void };
  disabled?: { (editor: Editor): boolean };
  group?: number;
  ext?: any;
} & (
  | {
      icon: string;
    }
  | {
      textIcon: string;
    }
);

export const createComponent = (options: {
  name: string;
  marks: EditorTool[];
  history?: boolean;
  extensions?: any[];
}) => {
  const extensions = [
    ...(options.extensions || []),
    ...options.marks.map((item) => item.ext).filter((ext) => !!ext),
  ];

  if (options.history) {
    extensions.push(History);
  }

  return defineComponent({
    name: options.name,
    props: {
      modelValue: { type: String, default: "" },
      placeholder: { type: String },
      height: { type: [String, Number] },
      minHeight: { type: [String, Number], default: "150px" },
      maxHeight: { type: [String, Number] },
      disabled: { type: Boolean, default: false },
      readonly: { type: Boolean, default: false },
      outlined: { type: Boolean, default: false },
      counter: { type: [Number, String] },
      errorMessages: { type: Array as PropType<string[]> },
    },
    setup(props, ctx) {
      const editor = ref<Editor>();

      const updating = ref(false);

      const render = () => {
        editor.value?.destroy();

        editor.value = new Editor({
          content: props.modelValue,
          editable: !(props.disabled || props.readonly),
          extensions: [
            Document,
            Paragraph,
            Text,
            Code,
            Typography,
            ListItem,
            ColorHighlighter,
            SmilieReplacer,
            // StarterKit,
            ...extensions,
          ],
          onUpdate: () => {
            updating.value = true;
            nextTick(() => {
              ctx.emit("update:modelValue", editor.value!.getHTML());
              nextTick(() => {
                updating.value = false;
              });
            });
          },
        });
      };

      watch(
        () => props.modelValue,
        () => {
          if (updating.value) return;
          render();
        }
      );

      onMounted(render);

      return {
        editor,
      };
    },
    computed: {
      charCount() {
        // remove tags
        const words = (this.modelValue || "").replace(/(<([^>]+)>)/gi, "");
        return words.length;
      },
    },
    beforeDestroy() {
      this.editor?.destroy();
    },
    watch: {
      value(value) {
        if (this.editor) {
          const isSame = this.editor.getHTML() === value;
          if (isSame) {
            return;
          }
          this.editor.commands.setContent(value, false);
        }
      },
      disabled(value) {
        if (this.editor) {
          this.editor.setEditable(!value);
        }
      },
    },
    render() {
      const charCount = (() => {
        // remove tags
        const words = (this.modelValue || "").replace(/(<([^>]+)>)/gi, "");
        return words.length;
      })();

      const header = h(Header, {
        editor: this.editor,
        marks: options.marks,
        history: options.history,
        disabled: this.disabled,
        outlined: this.outlined,
      });
      const content = h(Content, {
        editor: this.editor,
        placeholder: this.placeholder,
        height: this.height,
        minHeight: this.minHeight,
        maxHeight: this.maxHeight || this.height || "300px",
        outlined: this.outlined,
        charCount,
      });
      const footer = h(Footer, {
        errorMessages: this.errorMessages,
        counter: this.counter,
        charCount,
      });

      return h("div", { class: ["editor", { outlined: this.outlined }] }, [
        header,
        content,
        footer,
      ]);
    },
  });
};
