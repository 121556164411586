<template>
  <div
    class="header flex-align py-1 gap-2 max-w-full overflow-x-auto"
    :class="{ 'px-2': outlined }"
  >
    <template v-if="editor">
      <template v-for="(g, i) in groupedMarks">
        <v-divider v-if="i" :key="`g-${i}`" class="my-1 mx-3" vertical />
        <v-btn
          v-for="item in g"
          :key="item.title"
          :title="item.title"
          :class="{ 'bg-black text-white': item.active }"
          @click="item.handle"
          :disabled="item.disabled"
          size="30"
          variant="text"
          active
          icon
        >
          <span v-if="item.textIcon">{{ item.textIcon }}</span>
          <v-icon v-else size="20">{{ item.icon }}</v-icon>
        </v-btn>
      </template>
      <v-spacer />
      <v-btn
        v-for="(item, i) in history_"
        :key="`h-${i}`"
        :title="item.shortcut ? `${item.title} — ${item.shortcut}` : item.title"
        @click="item.handle"
        :disabled="disabled"
        size="30"
        variant="text"
        active
        icon
      >
        <v-icon size="20">{{ item.icon }}</v-icon>
      </v-btn>
    </template>
  </div>
</template>

<script lang="ts">
import { Editor } from "@tiptap/vue-3";
import type { EditorTool } from "./base";
import { groupBy } from "lodash";

export default defineComponent({
  props: {
    editor: { type: Object as PropType<Editor> },
    marks: { type: Array as PropType<EditorTool[]>, required: true },
    history: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
  },
  computed: {
    groupedMarks() {
      const marks =
        (this.editor &&
          this.marks.map((item) => ({
            ...item,
            title: item.shortcut
              ? `${item.title} — ${item.shortcut}`
              : item.title,
            active: item.active
              ? !this.disabled && item.active(this.editor!)
              : undefined,
            handle: () => item.handle(this.editor!),
            disabled:
              this.disabled ||
              (item.disabled ? item.disabled(this.editor!) : false),
          }))) ||
        [];
      return Object.values(groupBy(marks, "group"));
    },
    history_() {
      return this.history && this.editor
        ? [
            {
              title: "Undo",
              shortcut: "Ctrl+Z",
              handle: () => this.editor!.chain().focus().undo().run(),
              icon: "undo",
            },
            {
              title: "Redo",
              shortcut: "Ctrl+Y",
              handle: () => this.editor!.chain().focus().redo().run(),
              icon: "redo",
            },
          ]
        : [];
    },
  },
});
</script>
