<template>
  <div class="footer py-2 px-4 flex">
    <div class="text-red">
      <VMessages
        :active="errorMessages && !!errorMessages.length"
        :messages="errorMessages"
      />
    </div>
    <v-spacer />
    <small v-if="counter"> {{ charCount }} / {{ counter }}</small>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    errorMessages: { type: Array as PropType<string[]> },
    counter: { type: [Number, String] },
    charCount: { type: [Number, String] },
  },
});
</script>
